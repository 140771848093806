.cellInputContainer,
.selectedLabelCellContainer,
.labelCellContainer {
    --angle1: 2deg;
    --angle2: -3deg;
    --angle3: 5deg;
    overflow: visible;

    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    -moz-box-flex: 0;
    flex-grow: 0;
    position: relative;

    width: 8rem;
    height: 8rem;
    margin: 0;
    padding: 3px;

    text-align: center;

    border: solid 1px rgb(0, 0, 0);
    border-radius: 0px;
    background-color: rgb(252, 245, 166);

    font-family: 'Permanent Marker', cursive;
    font-weight: 400;
    font-style: normal;
}
.cellInputContainer {
    flex-direction: column;
    justify-content: space-evenly;
}
.labelCellContainer:nth-child(n),
.selectedLabelCellContainer:nth-child(n),
.cellInputContainer:nth-child(n) {
    transform: rotate(var(--angle1));
}
.labelCellContainer:nth-child(3n),
.selectedLabelCellContainer:nth-child(3n),
.cellInputContainer:nth-child(3n) {
    transform: rotate(var(--angle2));
}
.labelCellContainer:nth-child(7n),
.selectedLabelCellContainer:nth-child(7n),
.cellInputContainer:nth-child(7n) {
    transform: rotate(var(--angle3));
}
.selectedLabelCellContainer {
    background-color: lightblue;
    transition: 0.3s;
    transform: scale(1.1) rotate(0);
}
.cellInputContainer:hover {
    z-index: 10;
    transition: 0.3s;
    transform: scale(1.01);

    box-shadow: 2px 2px 1px 2px black;
    cursor: text;
}
.cellInputContainer:nth-child(n):hover {
    transform: rotate(calc(var(--angle1) - 2deg));
}
.cellInputContainer:nth-child(3n):hover {
    transform: rotate(calc(var(--angle2) + 2deg));
}
.cellInputContainer:nth-child(7n):hover {
    transform: rotate(calc(var(--angle3) - 2deg));
}
.cellInputContainer:focus-within:hover,
.cellInputContainer:focus-within {
    z-index: 11;
    transition: 0.3s;
    transform: scale(1.1) rotate(0);

    box-shadow: 2px 2px 15px 2px;
}
.labelCellContainer:hover {
    box-shadow: 0;
}
.emptyCell {
    border: solid 0px white;
}
.cellLabel {
    padding: 5px;

    background-color: lightyellow;
    border: black 1px dashed;
    border-radius: 18px;
}
.cellInput {
    font-family: 'Nothing You Could Do', cursive;
    font-weight: 800;
    font-size: larger;

    width: 75%;
    height: 25%;
    align-self: center;
    text-align: center;
    overflow: visible;

    border-radius: 5px 5px 0 0;
    border: 0;
    border-bottom: dashed 2px black;
    background-color: rgb(252, 245, 166);
    background-position: 0% 9%;
    transition: 0.3s;

    cursor: inherit;
}
.cellInput:focus {
    outline: none;
}
.majority {
    display: flex;
    flex-direction: column;

    margin-top: 5px;
    margin-bottom: 0;
    padding-top: 3px;
    padding: 0 5px;
    border: 1px grey dashed;
    border-radius: 2px;
    background-color: lightgreen;

    font-weight: 100;
    font-family: 'Nothing You Could Do', cursive;
}
.majorityLabel {
    font-size: small;
    text-decoration: underline;
}
.majorityAnswer {
    font-size: medium;
    font-weight: 400;
}
