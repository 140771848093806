.App {
    text-align: center;
    font-family: monospace;
}

.title {
    animation: fadeIn 0.5s;
}
footer {
    height: 100px;
    margin: 0;
    margin-top: -15px;
    padding-top: 10px;
    background-color: #1e2127;
    color: white;
}
.content {
    padding: 10px;
    padding-top: 3em;
    margin-bottom: 0;
}
h1 {
    animation: fadeIn 1.5s;
}
h2 {
    animation: fadeIn 1.5s;
}
h3 {
    animation: fadeIn 1.5s;
}
.infoSection {
    animation: fadeIn 5.5s;
}
.important {
    font-weight: 900;
    font-style: italic;
    color: bisque;
}
a {
    color: rgb(160, 185, 253);
    text-decoration: none;
    &:hover {
        transition: 0.25s;
        color: rgb(95, 111, 156);
        text-decoration: underline;
        text-decoration-color: rgb(95, 111, 156);
    }
}
.contentSection {
    display: flex;
    flex: 1;
    margin: 1em;
    justify-content: space-evenly;
    flex-direction: row;
    border: solid grey;
    border-radius: 8px;
    border-width: 1px;
    animation: fadeIn 1.5s;
}
.smallSection {
    display: flex;
    flex: 1;
    margin: 1em;
    flex-direction: column;
    border: solid grey;
    border-radius: 8px;
    border-width: 1px;
    animation: fadeIn 1.5s;
}
.skillsList {
    animation: fadeIn 1.5s;
    padding-left: 0;
    list-style-type: none;
    & .skillsTitle {
        font-weight: 900;
        font-style: italic;
        color: bisque;
        text-decoration: underline;
    }
    & li {
        color: bisque;
    }
}
.sectionTitle {
    padding: 1em;
    max-width: 30%;
    animation: fadeIn 1.5s;
    overflow-x: hidden;
}
.smallSectionTitle {
    padding: 1em;
    animation: fadeIn 1.5s;
    overflow-x: hidden;
}
.contentText {
    transition: 1s;
    animation: fadeIn 0.5s;
    max-width: 65%;
    padding: 15px;
}
.smallContentText {
    transition: 1s;
    animation: fadeIn 0.5s;
    padding: 15px;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.siteName {
    position: absolute;
    left: 2em;
    top: 0.3em;
    z-index: 1000;
    font-weight: bold;
    font-size: larger;
    font-style: italic;
    text-decoration: underline;
    animation: fadeIn 1.5s;
    cursor: default;
}
.smolSiteName {
    position: absolute;
    left: 0.5em;
    top: 0.3em;
    z-index: 1000;
    font-weight: bold;
    font-size: larger;
    font-style: italic;
    opacity: 50%;
    animation: fadeIn 1.5s;
    cursor: default;
}

.ListHeader {
    text-overflow: ellipsis;
    position: absolute;
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-content: center;
    text-align: center;
    text-decoration: none;
    overflow-x: hidden;
    background-color: #1e2127;
    border: 100px white;
    width: 100%;
    margin: 0;
    padding: 0;
    font-style: oblique;
    animation: fadeIn 0.5s;
    & li {
        height: 1em;
        cursor: pointer;
        padding: 0.5em 1em;
        color: rgb(160, 185, 253);
        text-decoration-color: rgb(160, 185, 253);
        transition: 0.25s;
        &:hover {
            background-color: #2d2d2e;
            transition: 0.25s;
            color: rgb(95, 111, 156);
            text-decoration: underline;
            text-decoration-color: rgb(95, 111, 156);
        }
    }
}

.App-header {
    background-color: #2b2f38;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: white;
}
.CompletedHeader {
    background: linear-gradient(
            rgba(255, 0, 0, 1) 0%,
            rgba(255, 154, 0, 1) 10%,
            rgba(208, 222, 33, 1) 20%,
            rgba(79, 220, 74, 1) 30%,
            rgba(63, 218, 216, 1) 40%,
            rgba(47, 201, 226, 1) 50%,
            rgba(28, 127, 238, 1) 60%,
            rgba(95, 21, 242, 1) 70%,
            rgba(186, 12, 248, 1) 80%,
            rgba(251, 7, 217, 1) 90%,
            rgba(255, 0, 0, 1) 100%
        )
        0 0/100% 200%;
    animation: a 2s linear infinite;

    text-overflow: ellipsis;
    position: absolute;
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-content: center;
    text-align: center;
    text-decoration: none;
    overflow-x: hidden;
    border: 100px white;
    width: 100%;
    margin: 0;
    padding: 0;
    font-style: oblique;
    & li {
        height: 1em;
        cursor: pointer;
        padding: 0.5em 1em;
        color: rgb(160, 185, 253);
        text-decoration-color: rgb(160, 185, 253);
        transition: 0.25s;
        &:hover {
            background-color: #2d2d2e;
            transition: 0.25s;
            color: rgb(95, 111, 156);
            text-decoration: underline;
            text-decoration-color: rgb(95, 111, 156);
        }
    }
}
.my-node-enter {
    opacity: 0;
}
.my-node-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}
.my-node-exit {
    opacity: 1;
}
.my-node-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}

.imageLink {
    padding: 0.5em;
}
.invertImage {
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
    cursor: pointer;
    width: 4em;
    transition: 0.25s;
    &:hover {
        -webkit-filter: invert(50%);
        filter: invert(50%);
    }
}
.image {
    margin-left: 10px;
    width: 4em;
    transition: 0.25s;
    cursor: pointer;
    &:hover {
        filter: brightness(50%);
    }
}
.App-link {
    color: #61dafb;
}
.hiddenLink:hover {
    cursor: pointer;
    transition: 0.25s;
    color: rgb(95, 111, 156);
    text-decoration: underline;
    text-decoration-color: rgb(95, 111, 156);
}
.modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    border-radius: 8px;
    width: 50%; /* Could be more or less, depending on screen size */
    max-height: 65%;
    overflow-y: scroll;
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.jerry0 {
    opacity: 0%;
    cursor: pointer;
    transition: 0.1s;
    position: absolute;
    filter: brightness(250%) grayscale(1);
    height: 2em;
    right: 1em;
    &:hover {
        opacity: 100%;
    }
}
.jerry0Revealed {
    transition: 0.1s;
    position: absolute;
    filter: brightness(250%) grayscale(1);
    height: 2em;
    right: 1em;
}

.jerry1 {
    opacity: 10%;
    cursor: pointer;
    transition: 0.1s;
    filter: brightness(250%) grayscale(1);
    height: 2em;
    &:hover {
        opacity: 100%;
    }
}
.jerry2 {
    opacity: 10%;
    cursor: pointer;
    transition: 0.1s;
    filter: brightness(250%) grayscale(1);
    width: 3em;
    height: 5em;
    &:hover {
        opacity: 100%;
    }
}
.jerry3 {
    opacity: 10%;
    cursor: pointer;
    transition: 1.1s;
    filter: brightness(250%) grayscale(1);
    height: 3em;
    position: absolute;
    right: 20%;
    top: 10%;
    &:hover {
        opacity: 100%;
    }
}
.jerry1Revealed {
    filter: brightness(250%) grayscale(1);
    height: 2em;
    position: absolute;
    right: 2.2em;
    opacity: 100%;
}
.jerry2Revealed {
    filter: brightness(250%) grayscale(1);
    height: 2em;
    position: absolute;
    right: 3.4em;
    opacity: 100%;
}
.jerry3Revealed {
    filter: brightness(250%) grayscale(1);
    height: 2em;
    position: absolute;
    right: 4.6em;
    opacity: 100%;
}

@keyframes a {
    to {
        background-position: 0 -200%;
    }
}
