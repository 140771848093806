.dailyApp {
    min-width: 100vw;
    min-height: 100vh;

    background-color: rgb(255, 255, 255);
    background-image: url('https://www.transparenttextures.com/patterns/white-wall-2.png');
}
.dailyAppContainer {
    display: flex;
    align-content: center;
    justify-content: center;
}
.dailyGrid {
    display: grid;
    grid-template-columns: repeat(5, minmax(0px, 1fr));
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    gap: 10px;

    margin-top: 5rem;
    max-width: 800px;
    max-height: 800px;
}
.drawableBoard {
    width: 100vw;
    height: 100vh;
    z-index: -100;
}
.finalScore {
    font-size: larger;
    font-weight: bold;
    font-style: oblique;
    text-decoration: underline;
}
