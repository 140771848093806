
.modList {
    margin: 0;
    background-color: aliceblue;
    height: 100vh;;
}
.modListContainer {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 20px;
    justify-items: center;
    grid-template-rows: 100% 100%;
    grid-template-columns: 50% 50%;
    padding: 10px;
}
.modsListTitle {
    padding: 2em;
    margin: 0;
}
.modItem {
    padding: 2em;
    border: 1px black dashed;
    border-radius: 6px;
    margin: 5px;
    color: grey;
    transition: 100ms;
    max-width: 100%;
    max-width: fit-content;
    background-color: white;
}
.modItem:hover {
    color: black;
    background-color: aliceblue;
    text-decoration: none;
    transition: 100ms;
    transform: scale(1.01);
    box-shadow: black 2px 2px 10px;
}
.modItemTitle {
    margin: 0;
}